import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Benefit, InitialPricingRequest } from '../interfaces/stepone';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
import { LocalCacheService } from './localCache.service';

@Injectable({ providedIn: 'root' })
export class PricingService {
  //private allbenefitsUrl = 'api/allBenefits'; // URL to web api
  private allbenefitsUrl = 'assets/json/benefits.json';
  private pricingUrl = `${environment.baseAPIUrl}quote/product/pricing`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private globalService: GlobalService, private localCacheService: LocalCacheService) {}

  /** GET All Benefits from the server */
  getAllBenefits(): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(this.allbenefitsUrl).pipe(
      tap((_) => console.log('fetched all benefits')),
      catchError(
        this.globalService.handleError<Benefit[]>(
          'fetched all benefits error',
          [],
        ),
      ),
    );
  }

  /** GET All pricing from the server */
  getInitialPricing(values: any): Observable<any> {
    this.globalService.showLoading('getInitialPricing');
    const initialPricingRequest = this.buildInitialPricingRequest(values);
    return this.http
      .post<any>(this.pricingUrl, initialPricingRequest, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getInitialPricing');
          console.log('fetched initial pricing');
        }),
        catchError(this.globalService.handleError<any>('initial pricing', [])),
      );
  }

  private buildInitialPricingRequest(values: any): InitialPricingRequest {
    let pricingRequest = {} as InitialPricingRequest;

    pricingRequest.destinations = this.getCountryObject(
      values.travelDestination,
    );
    pricingRequest.state = values.state.code;
    pricingRequest.fromDate = moment(values.depart).format('DD/MM/YYYY');
    pricingRequest.toDate = moment(values.return).format('DD/MM/YYYY');
    if (values?.rateDate) {
      pricingRequest.rateDate = moment(values.rateDate).format('DD/MM/YYYY');
    }

    pricingRequest.clubCode = environment.autoClub;
    pricingRequest.firstPersonAge = values.age1 ? parseInt(values.age1) : 0;
    pricingRequest.secondPersonAge = values.age2 ? parseInt(values.age2) : 0;
    pricingRequest.channel = 'Online';
    pricingRequest.promoCode = values.promoCode;
    pricingRequest.isClubMember =
      typeof values.isClubMember === 'boolean'
        ? values.isClubMember
        : values.isClubMember === 'true';
    pricingRequest.isSingleTrip =
      values.tripType.toLowerCase() === 'single' ? true : false;
    var quoteDetail = this.localCacheService.getSessionStorage("quoteDetail");
    if(quoteDetail?.quoteId){
      pricingRequest.quoteGuid = quoteDetail?.quoteId;
    }
    return pricingRequest;
  }

  getCountryObject(countryList: any) {
    const countryArray: any[] = [];
    countryList.forEach((element: any) => {
      countryArray.push({
        countryCode: element.countryCode,
        countryName: element.countryName,
        ratingRegionName: element.ratingRegionName,
      });
    });
    return countryArray;
  }
}
