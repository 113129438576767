import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { WordingService } from '../../../services/wording.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-alfred',
  standalone: true,
  imports: [
    RouterLink,
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './alfred.component.html',
  styleUrl: './alfred.component.scss',
})
export class AlfredComponent {
  customWordings: any = {};
  constructor(
    private wordingService: WordingService,
    private titleService: Title,
  ) {
    this.titleService.setTitle('alfred');
  }

  ngOnInit(): void {
    this.loadWordings();
  }

  private loadWordings() {
    this.wordingService.getWordings().subscribe((wordings) => {
      wordings.forEach((wording) => {
        if (wording.field === 'app-alfred') {
          this.customWordings = wording.values;
        }
      });
    });
  }
}
