import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'ageFormat',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(dateOfBirth: string): string {
    // Set birthDate and today in Australia/Sydney timezone
    if(!dateOfBirth) return "";
    const birthDate = moment.tz(dateOfBirth, 'DD/MM/yyyy', 'Australia/Sydney');
    const today = moment.tz('Australia/Sydney');

    let age = today.year() - birthDate.year();

    // Adjust age if today’s date hasn't reached the birth date in the current year
    if (today.month() < birthDate.month() || 
       (today.month() === birthDate.month() && today.date() < birthDate.date())) {
      age--;
    }

    return age.toString();
  }
}
